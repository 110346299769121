import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/role/list.php',
    method : 'post',
    data : data
  })
}


//删除
export function deleteRole(data) {
  return request({
    url : '/role/delete.php',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteRole(data) {
  return request({
    url : '/role/deleteBatch.php',
    method : 'post',
    data : data
  })
}

//新增
export function createRole(data) {
  return request({
    url : '/role/saveRole.php',
    method : 'post',
    data : data
  })
}
//修改
export function updateRole(data) {
  return request({
    url :'/role/updateRole.php',
    method : 'post',
    data : data
  })
}

//更新权限
export function changePermission(data) {
  return request({
    url :'/role/changePermission.php',
    method : 'post',
    data : data
  })
}
